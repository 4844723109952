/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import App from './FeatureApp';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'onegraph-service': OneGraphServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
      '@oneaudi/onegraph-client': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const trackingService = featureServices['audi-tracking-service'];

    const oneGraphClient = featureServices['onegraph-service'].getApolloClient();

    return {
      render: () => {
        return <App oneGraphClient={oneGraphClient} trackingService={trackingService} />;
      },
    };
  },
};

export default featureAppDefinition;
