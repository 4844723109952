import * as React from 'react';
import { Query, QueryConfiguredCarByCarlineArgs, useQuery } from '@oneaudi/onegraph-client';
import styled from 'styled-components';
import configuredCarByCarline from './configured-car-by-carline.graphql';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI Variable', 'Segoe UI', system-ui,
    ui-sans-serif, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  max-width: 40rem;
`;

const OneGraph: React.FC = () => {
  const { data, error, loading } = useQuery<Query, QueryConfiguredCarByCarlineArgs>(
    configuredCarByCarline,
    {
      variables: {
        carlineInput: {
          brand: 'A',
          country: 'de',
          language: 'de',
          carlineId: 'a4limo',
        },
      },
    },
  );
  /* eslint-disable-next-line no-console */
  console.log(data, error);

  return (
    <Layout>
      <Card>
        <h2>OneGraph client demo</h2>
        <p>
          This Feature App demos the OneGraph client. If everything is setup correctly it displays
          the default car configuration for the A4 Limo carline .
        </p>
        <hr />
        {loading && <p data-test-id="onegraph-loading">Loading...</p>}
        {error && <p data-test-id="onegraph-error">An error occurred. {error.message}.</p>}
        {data && (
          <div>
            <p>Carline: {data.configuredCarByCarline.carline?.name}</p>
            <p>Model: {data.configuredCarByCarline.model?.name}</p>
            <p>Price: {data.configuredCarByCarline.prices?.total?.formattedValue}</p>
          </div>
        )}
      </Card>
    </Layout>
  );
};

export default OneGraph;
