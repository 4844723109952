/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import { ApolloClient, OneGraphProvider } from '@oneaudi/onegraph-client';
import OneGraph from './components/one-graph/OneGraph';
import Welcome, { WelcomeProps } from './components/welcome/Welcome';

interface FeatureAppProps extends WelcomeProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  oneGraphClient: ApolloClient<any>;
  trackingService?: TrackingServiceV2;
}

const FeatureApp: React.FC<FeatureAppProps> = ({
  exampleProp,
  oneGraphClient,
  trackingService = typeof window === 'undefined'
    ? undefined
    : createTrackingService(__FEATURE_APP_NAME__),
}: FeatureAppProps) => {
  React.useEffect(() => {
    if (!trackingService) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    trackingService.featureAppName = __FEATURE_APP_NAME__;

    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  return (
    <OneGraphProvider client={oneGraphClient}>
      <Welcome exampleProp={exampleProp} />
      <OneGraph />
    </OneGraphProvider>
  );
};

export default FeatureApp;
