import { Icon } from '@audi/audi-ui-react';
import React from 'react';

export interface WelcomeProps {
  exampleProp?: unknown;
}

const Welcome: React.FC<WelcomeProps> = () => {
  return (
    <h3 data-test-id="welcome">
      <Icon name="smiley-happy" size="xs" /> Welcome!
    </h3>
  );
};

export default Welcome;
